<!--
 * @Author: lbh
 * @Date: 2022-12-10 18:12:40
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 17:18:49
 * @Description: file content
-->
<template>
  <div class="qrCodeGraphicText2-edit">
    <div class="cell-b">
      <h3>展示類型</h3>
      <el-select
        v-model="configs.type"
        placeholder="请选择"
        @change="setValue('type')"
      >
        <el-option
          label="默認"
          value="0"
        />
        <el-option
          label="帶箭頭"
          value="1"
        />
      </el-select>
      <div class="cell-b">
        <h3>主標題</h3>
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
          type="textarea"
          rows="3"
        />
      </div>
      <div class="cell-b">
        <h3>副標題 </h3>
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}subTitle`]"
          type="textarea"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}subTitle`)"
          rows="3"
        />
      </div>
      <div class="cell-b">
        <h3>描述內容</h3>
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}desc`]"
          type="textarea"
          :placeholder="`請輸入-${lItem.name}`"
          rows="3"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}desc`)"
        />
      </div>

      <h3>內容-居中內容</h3>
      <el-select
        v-model="configs.descAlign"
        @change="setValue('descAlign')"
      >
        <el-option
          label="left"
          value="left"
        ></el-option>
        <el-option
          label="center"
          value="center"
        ></el-option>
        <el-option
          label="right"
          value="right"
        ></el-option>
      </el-select>
      <!-- <h3>背景色</h3>
      <el-color-picker
        v-model="configs.bgColor"
        @change="colorChange"
      ></el-color-picker> -->
      <div class="cell-b">
        <h3>
          中心內容
          <selfTips :content="$tipText.textWrap" />
        </h3>
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}centerTitle`]"
          :placeholder="`請輸入-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}centerTitle`)"
        />
      </div>

    </div>
    <div class="cell-b">
      <h3>子項配置
        <selfTips :content="$tipText.textWrap" />
      </h3>
      <selfCell
        title="顏色總控"
        tipContent="控制所有子項的背景色"
      >
        <el-color-picker
          v-model="childBgColor"
          @change="childColorChange"
        ></el-color-picker>
      </selfCell>
      <div class="child-box">
        <div
          v-for="(item,index) in configs.items"
          :key="index"
        >
          <div class="child-cell cell-b">
            <div class="cell">
              <!-- 顏色 -->
              <el-color-picker
                v-model="item.bgColor"
                @input="setValue('items')"
              ></el-color-picker>
              <!-- 操作 -->
              <el-button-group class="btn-group">
                <el-button
                  type="primary"
                  icon="el-icon-upload2"
                  :disabled="index == 0"
                  size="mini"
                  @click="itemCheck(index,index-1)"
                ></el-button>
                <el-button
                  type="success"
                  icon="el-icon-download"
                  :disabled="index == configs.items.length-1"
                  size="mini"
                  @click="itemCheck(index,index+1)"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  :disabled="configs.items.length == 1"
                  @click="itemDel(index)"
                ></el-button>
                <el-button
                  type="warning"
                  icon="el-icon-plus"
                  size="mini"
                  @click="itemAdd(index)"
                ></el-button>
              </el-button-group>
            </div>
            <!-- 文本 -->
            <el-input
              v-for="lItem in language"
              :key="lItem.code"
              v-model="item[`${lItem.code=='HK'?'':lItem.code}text`]"
              @input="setValue('items')"
              :placeholder="`請輸入-${lItem.name}`"
              class="itemText"
            ></el-input>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
export default {
  name: "qrCodeGraphicText2",
  mixins: [langEditMixin],
  props: {
    configs: {
      default () {
        return {
          bgColor: "",
          itemPadding: "0",
          items: [],
          subTitle: "",
          title: "",
          type: '',
        };
      },
    },
    index: {
      default () {
        return 0;
      },
    },
  },
  data () {
    return {
      childBgColor: '#FFFFFF'
    }
  },
  created () {
    this.childBgColor = this.configs.items[0].bgColor;
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
    colorChange (e) {
      console.log(e);
      this.$emit('setValue', { key: 'bgColor', value: e });
    },
    childColorChange (e) {
      let items = this.configs.items;
      for (let i in items) {
        items[i].bgColor = e;
      }
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 切換順序
    itemCheck (index, newIndex) {
      let items = this.configs.items;
      let _ = items.splice(index, 1);
      items.splice(newIndex, 0, _[0])
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 刪除
    itemDel (index) {
      let items = this.configs.items;
      items.splice(index, 1);
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 新增
    itemAdd (index) {
      let items = this.configs.items;
      items.splice(index + 1, 0, this.$util.object.clone(items[index]))
      this.$emit('setValue', { key: 'items', value: items });
    }
  },
}
</script>

<style lang="less" scoped>
.qrCodeGraphicText2-edit {
  .child-box {
    .child-cell {
      margin-bottom: 10px;
      .cell {
        display: flex;
        align-items: center;
        .itemIcon,
        .itemText {
          margin-right: 10px;
        }
        .btn-group {
          margin-left: auto;
        }
        margin-bottom: 5px;
      }
    }
  }
}
</style>